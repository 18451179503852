<template>
    <div class='ladderControl-contaner editOver-container'>
        <div class="result-panel" style="margin-top: 0;">
            <CSTable :thead-top="filterHeight">
                <template v-slot:header>
                    <div class="table-header-panel"
                         style="text-align: right;display: flex;justify-content: space-between;align-items: center">
                        <div class="config-tip">
                            <svg
                                    class="icon"
                                    aria-hidden="true"
                            >
                                <use xlink:href="#icon-menua-zu92"></use>
                            </svg>
                            添加的人员可显示指定楼栋楼层的乘梯二维码。
                        </div>
                        <button
                                class="btn btn-primary sticky-right"
                                @click="openPanl({},false)"
                        >
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            人员
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th class="text-center">添加时间</th>
                        <th class="text-center">姓名</th>
                        <th class="text-center">手机号</th>
                        <th class="text-center">所属公司</th>
                        <th class="text-center">指定楼栋楼层</th>
                        <th class="text-center">操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="item in ueryAllSettingList" :key="item.id">
                        <td class="text-center" style="min-width: 125px">
                            {{item.createTime}}
                        </td>

                        <td class="text-center">
                            {{item.name || '-'}}
                        </td>
                        <td class="text-center">
                            {{item.phone}}
                        </td>
                        <td class="text-center">
                            {{item.companyName || '-'}}
                        </td>
                        <td class="text-center">
                            <span
                                    class="allow-click"
                                    @click="checkLadder(item.relevances)"
                            >查看</span>
                        </td>

                        <td class="text-center">

                            <div class="btn-group">
                                <button
                                        type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                >
                                    操作
                                </button>

                                <ul class="dropdown-menu" style="width: 115px">
                                    <li>
                                        <a
                                                style="width: 100%; text-decoration: none"
                                                @click="openPanl(item,true)"
                                        >
                                            修改楼栋楼层
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                                style="width: 100%; text-decoration: none"
                                                @click="_openDeleteInspectionPlanModel(item.id)"
                                        >
                                            删除
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </template>
            </CSTable>

            <Pagination name="pagination" componentName="Pagination" />
        </div>
        <!--        查看指定楼栋楼层-->
        <CSDialog
                dialog-title="指定楼栋楼层"
                dialog-width='340px'
                class="tenant-detail-dialog"
                :dialog-visible="dealResultVisible"
                @onClose="dealResultVisible = false"
                :dialog-show-confirm-btn="false"
                dialog-cancel-btn-text="关闭"
        >
            <template v-slot:dialog-content>
                <div style="font-size: 24px;text-align: center;margin-top: 20px;margin-bottom: 20px;height:195px;overflow:auto">
                    <div v-for="item in  checkArr" :key="item" style="margin-bottom: 10px">
                        {{item}}
                    </div>
                </div>

            </template>
        </CSDialog>
        <CSDialog
                :dialogVisible="addPanelGroupVisible"
                dialogWidth="570px"
                :dialogTitle=" isUpdate?'修改人员':'添加人员'"
                @onClose="addPanelGroupDialogClose"
                @onConfirm="addEidtPanelGroup"
        >
            <div slot="dialog-content" style="padding: 30px;" @click="closeT">

                <div class="notice_box">
                    <div class="notice_title" style="width: 120px;">选择人员</div>
                    <div style="width: 347px">
                        <ChooseLocation
                                mission="deputy"
                                style="--readonly-input-width: 307px"
                                @changeLocation="changeLocation"
                                :showInput="false"
                                :isBangBang="true"
                                :defaultLocationName = 'defaultLocationName'
                        />
                    </div>
                </div>
                <div style="display: flex;margin-top: 10px">
                    <div class="notice_title" style="width: 110px"></div>
                    <p style="font-size: 20px; color: #999; padding-left: 10px">

                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-menua-zu92"></use>
                        </svg>
                        只显示已注册棒棒我鸭用户。
                    </p>
                </div>

                <div class="notice_box building-item-content" style="margin-top:10px;margin-bottom:2px; ">
                    <div class="notice_title " style="vertical-align: top; width: 120px">
                        选楼栋楼层
                    </div>

                    <AllRoomSelectOwner
                            :relevanceBuilding="!relevanceBuilding?[]:relevanceBuilding"
                            @changeContractRooms="changeContractRooms"
                            :relevance="relevance?relevance:[]"
                            :selectedRooms="roomIds"
                            :isSelectedRooms="isSelectedRooms"
                            :checkedRoomNames="checkedRoomNames"
                            :topBuilding="300"
                            :isDisable="true"
                            style="display: inline-block;font-size: 24px"
                    ></AllRoomSelectOwner>
                </div>
            </div>
        </CSDialog>
    </div>
    <!--    添加人员-->


</template>

<script>
    import CSTable from "@/components/common/CSTable";
    import Pagination from "@/components/Pagination.vue";
    import CSDialog from "@/components/common/CSDialog";
    import ChooseLocation from "@/components/ChooseLocation";
    import AllRoomSelectOwner from "@/components/AddFloorSelect";
    //editElevatorConfigUrl修改接口
    import {
        queryAllSettingUrl, ueryAllSettingUrl, deleteElevatorConfigUrl, editElevatorConfigUrl, addElevatorConfigUrl
    } from "@/requestUrl";

    export default {
        name: "LadderControl",
        created() {
            console.log('执行');


            //查询指定人员列表
            this.ueryAllSetting();
            this.$vc.on(this.$route.path , "pagination_page", "event", this.ueryAllSetting);
        },
        data() {
            return {
                defaultLocationName:'',
                checkArr:[],//查看萨达
                relevance: null,
                relevanceBuilding: '',
                relevanceBuildings: [],
                checkedRoomNames: [],
                isSelectedRooms: [],
                roomIds: [],//选中楼栋
                buildDatas: [],
                userId: '',
                staffTreeData: [],
                staffTreeVisible: false,
                checkedStaffs: {},
                staffId: [],
                urId:'',
                isUpdate: false,

                ueryAllSettingList: [],//电梯列表

                filterHeight: 0,
                dealResultVisible: false,//指定楼栋楼
                addPanelGroupVisible: false, //添加或修改人员
                addPanelGroupInfo: {
                    name: '',//选择的人员名称
                }
            }
        },
        components: {
            CSTable,
            Pagination,
            CSDialog,
            ChooseLocation,
            AllRoomSelectOwner,
        },
        mounted() {
            document.body.addEventListener('click', () => {
                this.staffTreeVisible = false;
            })
        },
        methods: {
            closeT(){
                this.$vc.emit("allRoomSelectContract", "selcet", {});
            },
            changeContractRooms(roomInfo) {

                let selectArr = [];
                let floorList = [];
                let buildingIdList = [];
                for (var i = 0; i < roomInfo.length; i++) {
                    let str = roomInfo[i].split('-')[0];
                    let strd = roomInfo[i].split('-')[1];
                    let buildId = roomInfo[i].split('-')[2];
                    if (!selectArr.includes(str)) {
                        selectArr.push(str);
                        buildingIdList.push(buildId);
                    }
                    floorList.push({
                        building: str,
                        value: strd
                    })
                }
                console.log(floorList);
                this.relevanceBuildings = selectArr.map((item, index) => {
                    return {
                        relevanceCode: buildingIdList[index],
                        relevanceID: item,
                        relevanceFloor: floorList.filter(res => res.building == item).map(gg => +gg.value)
                    }
                })
                //选中的数组
                console.log('选中的数组');
                console.log(this.relevanceBuildings);
                this.buildDatas = this.relevanceBuildings.map(item => {
                    return {
                        buildingId:item.relevanceID,
                        buildingCode:item.relevanceCode,
                        floors:item.relevanceFloor
                    }
                });
            },
            staffTreeVisibleBtn() {

                this.staffTreeVisible = true;
            },
            //查询指定人员
            ueryAllSetting(pageNo = 1, pageSize = 10) {
                this.$fly.post(ueryAllSettingUrl, {
                    "regionCode": this.$vc.getCurrentRegion().code,  //区域code
                    "pageSize": 10,       //显示条数
                    "pageNo": pageNo           //页码
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    if (pageNo === 1) {
                        this.$vc.emit(this.$route.path , "pagination", "init", {
                            total: res.data.total,
                            pageSize,
                            currentPage: pageNo,
                        });
                    }
                    this.ueryAllSettingList = res.data.datas
                })
            },

            //获取用户ID
            changeLocation(e) {
                this.userId = e.id;
            },
            //关闭添加
            addPanelGroupDialogClose() {
                this.addPanelGroupVisible = false;
            },
            //打开添加或修改页面
            openPanl(obj, isU) {
                this.isUpdate = isU;
                if(isU){
                    let green = obj.gender ==0 ?'未知':obj.gender== 1?'男':'女'
                    let name = obj.name||'未知';
                    this.defaultLocationName = name  +'-'+green+ '-' + obj.phone;
                    this.relevanceBuilding = '';
                    let checkArr = [];
                    for(let i = 0; i<obj.relevances.length;i++){
                        let  floors = JSON.parse(obj.relevances[i].floors);
                        for(var j = 0; j < floors.length;j++){
                            checkArr.push(`${obj.relevances[i].buildingName}${floors[j]}层`)
                        }
                    }

                    let arr = obj.relevances.map(item =>   {
                        return {
                            'relevanceBuildingId':item.buildingId,
                            relevanceCode:item.buildingCode,
                            relevanceFloor:JSON.parse(item.floors)
                        }

                    })

                    this.relevance = arr;
                    this.relevanceBuilding = checkArr;

                    ///id
                    this.urId = obj.id;
                }else{
                    this.relevanceBuilding = '';
                    this.defaultLocationName = '';
                    this.userId = '';
                    this.relevance = null;
                    this.urId = '';
                }
                this.staffTreeData = [];

                this.addPanelGroupVisible = true;
            },
            //添加修改人员
            addEidtPanelGroup() {
                console.log(321321);
                let url = ''
                if (this.isUpdate) {
                    //修改
                    url = editElevatorConfigUrl;
                    this.$fly.post(url, {
                        id: this.urId,       //用户id
                        "buildDatas": this.buildDatas
                    }).then(res => {
                        if (res.code != 0) {
                            return
                        }
                        this.$vc.toast('修改成功');
                        this.ueryAllSetting();
                        this.addPanelGroupVisible = false;
                    })
                } else {
                    //添加
                    url = addElevatorConfigUrl;
                    this.$fly.post(url, {
                        "regionCode": this.$vc.getCurrentRegion().code,   //区域code
                        "userId": this.userId,       //用户id
                        "buildDatas": this.buildDatas
                    }).then(res => {
                        if (res.code != 0) {
                            return
                        }
                        this.$vc.toast('添加成功');
                        this.ueryAllSetting();
                        this.addPanelGroupVisible = false;
                    })
                }

            },
            //查看楼栋
            checkLadder(item) {
                console.log(item);
                this.checkArr = [];
                for(let i = 0; i<item.length;i++){
                    let  floors = JSON.parse(item[i].floors);
                    for(var j = 0; j < floors.length;j++){
                        this.checkArr.push(`${item[i].buildingName}-${floors[j]}层`)
                    }
                }

                this.dealResultVisible = true;

            },

            _openDeleteInspectionPlanModel: function (_inspectionPlan) {
                this.$CSDialog.confirm({
                    title: '提示',
                    message: '确定删除吗?',
                    onConfirm: () => {
                        this.$fly.get(deleteElevatorConfigUrl, {id: _inspectionPlan}).then(res => {
                            if (res.code != 0) {
                                return
                            }
                            this.$vc.toast('删除成功');
                            this.$CSDialog.instance.closeDialog();
                            this.ueryAllSetting();
                        })

                    }
                })
            },
        }
    }
</script>

<style lang="stylus" scoped>

    .config-tip {
        color: #999999;
    }

    .notice_box {
        display: flex;
        align-items: center;
    }

    .notice_title {
        display: inline-block;
        font-size: 24px;
        width: 144px;
        color: #000;
        text-align: right;
        margin-right: 40px;
        vertical-align: middle;

    }


</style>
